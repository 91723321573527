<template>
  <div>
    <div class="grid">
      <div class="col-12 md:col-6">
        <h3>Autorizadores Especiais</h3>
      </div>
    </div>

    <div class="grid" v-if="logisticaAtual == null">
      <div class="col-12">
        <div class="border-pink-500 surface-overlay border-2 border-round font-bold text-2xl p-3 flex align-items-center justify-content-center">
            Selecione a Empresa de Logística primeiro.
        </div>
      </div>
    </div>

    <div class="grid" v-else>
      
      <div class="col-12 md:col-8" id="usuarios">
        <DataTable :value="usuarios" :lazy="true" :loading="loading">
          <Column class="col-codigo" field="codigo" header="Código"></Column>
          <Column class="col-nome" field="nome" header="Nome"></Column>
          <Column class="col-actions">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning mr-2"
                @click="alterarForm(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger"
                @click="excluir(slotProps.index, $event)"
              />
            </template>
          </Column>

          <template #empty> Nenhum Autorizador Especial encontrado. </template>
        </DataTable>
      </div>

      <div class="col-12 md:col-4">

        <div class="card p-fluid">
          <h5 v-if="usuario == null">Novo Autorizador:</h5>
          <h5 v-else>Alteração do Autorizador: </h5>

          <div class="grid">

            <div class="p-field col-12">
              <label for="codigo">Código</label>
              <InputText id="codigo" type="text" v-model="codigo" />
            </div>

            <div class="p-field col-12">
              <label for="nome">Responsável</label>
              <InputText id="nome" type="text" v-model="nome" />
            </div>

            <div class="p-field col-12">
              <label for="senha">Senha</label>
              <Password id="senha" v-model="senha" />
            </div>

            <div class="p-field col-12">
              <label for="autorizacoes" class="mb-3">Autorizações:</label>
              <div v-for="o of opcoes" :key="o.id">
                <Checkbox name="autorizacoes" :value="o.id" v-model="autorizacoes" />
                <span class="ml-2">{{ o.nome }}</span>
              </div>
            </div>

            <div class="col-12 mt-3">
              <div class="grid">
                <div class="col-6">
                  <Button
                    label="Gravar"
                    icon="pi pi-check"
                    class="p-button-success"
                    @click="criar_alterar"
                  ></Button>
                </div>
                <div class="col-6" v-if="usuario != null">
                  <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    class="p-button-warning"
                    @click="cancelarAlteracao"
                  ></Button>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <ConfirmPopup></ConfirmPopup>
    <Toast position="top-right" />

  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

export default {
  data() {
    return {

      // Dados cadastrados
      usuarios: [],
      logisticaAtual: null,

      // Controle de Apresentação
      loading: false,

      // Dado selecionado
      usuario: null,

      // Campos do Formulário
      codigo: "",
      nome: "",
      senha: "",
      autorizacoes: [],

      //Chaves Estrangeiras
      opcoes: [
        {
          id: "LANCAMENTO_MANUAL",
          nome: 'Liberação de lançamento manual',
        }
      ],

    };
  },

  mounted() {

    if(this.$root.logisticaAtual != null) {
      this.logisticaAtual = this.$utils.getStdObject(this.$root.logisticaAtual);
    }

  },

  watch: {

    '$root.logisticaAtual': {
      handler: function(val) {
        if(this.logisticaAtual == null || val.id != this.logisticaAtual.id)
          this.logisticaAtual = this.$utils.getStdObject(val);
      },
      deep: true,
      immediate: true
    },

    logisticaAtual: function(val) {
        const self = this;
        this.usuarios = [];
        if(val != null) {
            this.loading = true;

            firebase.database().ref("Autorizadores").child(val.id)
            .once("value", (snapshot) => {

                if (snapshot.exists()) {

                    for(let u of Object.values(snapshot.val())) {
                    
                      self.usuarios.push(u);

                    }

                }

                self.loading = false;

            });

        }
    },

    usuario: function (val) {

      if (val == null) {
        this.limparFormulario();
      } else {
        //Popula a tela de Alteração
        this.codigo = val.codigo;
        this.nome = val.nome;
        this.senha = val.senha;
        this.autorizacoes = val.autorizacoes;
      }

    },
  },

  methods: {

    // // // Métodos padrões para este modelo de tela // // //

    limparFormulario() {
      //Limpa as variáveis
      this.codigo = "";
      this.nome = "";
      this.senha = "";
      this.autorizacoes = [];
      //limpa o objeto
      this.usuario = null;    
    },

    // // // AÇÕES DO USUÁRIO // // //

    cancelarAlteracao() {
      this.limparFormulario();
    },

    excluir(idx, event) {
      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Tem certeza que deseja excluir?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: () => {

          firebase.database().ref("Autorizadores").child(self.logisticaAtual.id).child('-' + self.usuarios[idx].codigo).remove().then(() => {

            self.usuarios.splice(idx, 1);

            self.$toast.add({
                severity: "success",
                summary: "Sucesso!",
                detail: "Autorizador excluído com sucesso!",
                life: 3000,
            });

          });
        
        }
      });
    },

    criar_alterar() {
      const self = this;

      if(this.codigo == "" || this.nome == "" || this.senha == "") {
        alert('Preencha corretamente todas as informações!');
        return;
      }

      const dup = this.usuarios.filter(function(u) { return u.codigo == self.codigo});
      if(this.usuario == null && dup.length > 0 ) {
        alert('Código já cadastrado! Informe outro.');
        return;
      }

      const dados = {
          "logistica": self.logisticaAtual.id,
          "codigo": self.codigo,
          "nome": self.nome,
          "senha": self.senha,
          "autorizacoes": self.autorizacoes
      };

      if(self.usuario != null && self.codigo != self.usuario.codigo) {
        firebase.database().ref("Autorizadores").child(this.logisticaAtual.id).child('-' + this.usuario.codigo).remove();
      }

      firebase.database().ref("Autorizadores").child(this.logisticaAtual.id).child('-' + this.codigo).update(dados).then(function() {

        let criado = true;
        for (const k in self.usuarios) {
            if (self.usuarios[k].codigo == self.usuario.codigo) {
                self.usuarios[k] = dados;
                criado = false;
                break;
            }
        }

        if(criado) {
          self.usuarios.push(dados);
        }

        self.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Autorizador gravado com sucesso!",
            life: 3000,
        });

        self.cancelarAlteracao();

      }).catch(function(error) {

        if (error) {
            self.$toast.add({
                severity: "error",
                summary: "ERRO!",
                detail: error.message,
                life: 5000,
            });
        }

      });

    },

    alterarForm(obj) {
      this.usuario = obj;
      window.scrollTo(0,0);
    },
    
  },
};
</script>

<style>
  #usuarios tr td:nth-child(4), #usuarios tr th:nth-child(4) {
    width: 65px;
  }

  #usuarios tr td:nth-child(3), #usuarios tr th:nth-child(3) {
    width: 110px;
  }
</style>